<template>
    <div>
        <a-drawer
            class="meeting_show"
            :class="isMobile && 'meeting_show_mobile'"
            :visible="visible"
            destroyOnClose
            :afterVisibleChange="afterVisibleChange"
            @close="visible = false"
            :width="drawerWidth"
            placement="right">
            <div class="drawer_header flex items-center justify-between truncate">
                <a-skeleton
                    v-if="loading"
                    active
                    :paragraph="{ rows: 1 }" />
                <div v-else class="title truncate">
                    <template v-if="meeting">
                        {{ meeting.name }}
                    </template>
                </div>
                <div class="close pl-2 flex items-center">
                    <template v-if="meeting">
                        <Status
                            :status="meeting.status"
                            class="mr-2" />
                        <component
                            :is="moreMenuWidget"
                            class="mr-1"
                            :isAuthor="isAuthor"
                            :onlyMoreButton="onlyMoreButton"
                            :meeting="meeting"
                            :closeConference="closeConference"
                            :inviteLink="inviteLink"
                            :share="share"
                            :deleteConference="deleteConference"
                            :openEditDrawer="openEditDrawer" />
                    </template>
                    <a-button
                        @click="visible = false"
                        class="text-current"
                        type="ui"
                        shape="circle"
                        flaticon
                        ghost
                        icon="fi-rr-cross" />
                </div>
            </div>
            <div class="drawer_body">
                <a-skeleton
                    v-if="loading"
                    active />
                <template v-else>
                    <template v-if="meeting">
                        <CreateDrawer
                            :emded="true"
                            :parentVisible="editDrawer"
                            :zIndex="1050"
                            :updateMeeting="updateMeeting"
                            :closeDrawer="closeEditDrawer"
                            :meeting="meeting" />
                        <div
                            v-if="meeting"
                            class="meeting_tag flex items-center pb-4">
                            <a-tag>
                                <span class="flex items-center">
                                    <i class="flex items-center fi fi-rr-calendar-lines mr-1"></i>
                                    {{$moment(meeting.date_begin).format('DD.MM.YYYY в HH:mm')}}
                                </span>
                            </a-tag>
                            <a-tag>
                                <span class="flex items-center">
                                    <i class="flex items-center fi-rr-clock-nine mr-1"></i>
                                    {{ dFormat }}
                                </span>
                            </a-tag>
                            <a-tag
                                v-if="isAuthor"
                                color="green">
                                {{ $t('meeting.author') }}
                            </a-tag>
                        </div>
                        <div
                            v-if="meeting && meeting.description"
                            class="desc">
                            <p class="break-words">
                                {{ descLength }}
                            </p>
                            <div v-if="showDescBtn">
                                <span
                                    class="desc_more"
                                    @click="showDesc = !showDesc">
                                    {{ showDesc ? $t('meeting.hide') : $t('meeting.more') }}
                                </span>
                            </div>
                        </div>
                        <a-divider v-if="meeting && meeting.description"  />
                        <div class="user_list">
                            <label class="font-semibold mb-2 block">
                                {{ $t('meeting.participants') }} ({{ memberCount }})
                            </label>
                            <UserCard
                                v-for="mUser in userList.results"
                                :key="mUser.user.id"
                                :user="mUser.user"
                                :isModerator="mUser.is_moderator"
                                :meeting="meeting" />
                            <infinite-loading
                                v-if="userList.next"
                                :identifier="meeting.id"
                                @infinite="getMeetingUser"
                                v-bind:distance="10">
                                <div slot="spinner"><a-spin /></div>
                                <div slot="no-more"></div>
                                <div slot="no-results"></div>
                            </infinite-loading>
                        </div>
                        <RecordDrawer
                            v-if="visible"
                            v-model="recVisible"
                            :id="meeting.id"/>
                    </template>
                </template>
            </div>
            <div class="drawer_footer flex items-center">
                <a-skeleton
                    v-if="loading"
                    active
                    :paragraph="{ rows: 1 }" />
                <template v-else>
                    <template v-if="meeting">
                        <a
                            v-if="meeting.status !== 'ended'"
                            :href="meeting.target"
                            :class="isMobile ? 'ant-btn-lg ant-btn-block' : 'mr-2'"
                            class="ant-btn ant-btn-primary"
                            target="_blank">
                            <i class="fi fi-rr-sign-in-alt icon"></i> {{ $t('meeting.connect') }}
                        </a>
                        <a-button
                            v-if="meeting && meeting.has_record"
                            :block="isMobile ? true : false"
                            :size="isMobile ? 'large' : 'default'"
                            type="ui"
                            :class="meeting.status !== 'ended' && 'ml-2'"
                            @click="recVisible = true">
                            {{ $t('meeting.videos') }}
                        </a-button>
                    </template>
                </template>
            </div>
        </a-drawer>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { declOfNum, durationFormat } from '../../utils'
import CreateDrawer from '../CreateDrawer'
import Status from '../Status.vue'
import eventBus from '../../utils/eventBus'
import globalEventBus from '@/utils/eventBus'
import InfiniteLoading from 'vue-infinite-loading'
import UserCard from './UserCard.vue'
import RecordDrawer from '../RecordDrawer.vue'
export default {
    name: "MeetingShowDrawer",
    components: {
        CreateDrawer,
        UserCard,
        InfiniteLoading,
        RecordDrawer,
        Status
    },
    props: {
        pageName: {
            type: String,
            default: 'page_list_meetings.PlannedMeetingModel'
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            windowWidth: state => state.windowWidth
        }),
        dFormat() {
            return durationFormat(this.meeting.duration)
        },
        drawerWidth() {
            if(this.windowWidth > 500)
                return 500
            else {
                return '100%'
            }
        },
        memberCount() {
            return this.meeting.members_count + ' ' + declOfNum(this.meeting.members_count,
                [this.$t('meeting.participant'), this.$t('meeting.participantGen'), this.$t('meeting.participantsGen')])
        },
        descLength() {
            if(!this.showDesc && this.meeting?.description?.length > 175)
                return this.meeting.description.substr(0, 175) + '...'
            else
                return this.meeting.description
        },
        desc() {
            if(this.meeting.description) {
                if(this.meeting.description && this.meeting.description.length > 110)
                    return this.meeting.description.substr(0, 110) + '...'
                else
                    return this.meeting.description
            } else
                return null
        },
        isAuthor() {
            if(this.user && this.user.id === this.meeting.author.id) {
                return true
            } else
                return null
        },
        isMobile() {
            return this.$store.state.isMobile
        },
        moreMenuWidget() {
            if(this.isMobile)
                return () => import('./MoreMenuMobile.vue')
            return () => import('./MoreMenuDropdown.vue')
        },
        onlyMoreButton() {
            return this.meeting.status === 'ended'
        }
    },
    data() {
        return {
            visible: false,
            loading: false,
            meeting: null,
            recordLoading: false,
            editDrawer: false,
            showDesc: false,
            showDescBtn: false,
            userLoading: false,
            recVisible: false,
            page: 0,
            recordList: [],
            userList: {
                results: [],
                next: true,
                count: 0
            }
        }
    },
    watch: {
        '$route.name'() {
            if(this.$route.query?.meeting)
                this.visible = false
        },
        '$route.query.meeting'(val) {
            if(val && !this.visible)
                this.openDrawer()
            else this.visible = false
        }
    },
    methods: {
        afterVisibleChange(vis) {
            if(vis)
                this.getMeeting()
            else
                this.close()
        },
        share() {
            this.$store.commit('share/SET_SHARE_PARAMS', {
                model: 'tasks.TaskModel',
                shareId: this.meeting.id,
                object: this.meeting,
                shareUrl: `${window.location.origin}/?meeting=${this.meeting.id}`,
                shareTitle: `${this.$t('meeting.conference')} - ${this.meeting.name}`
            })
        },
        deleteConference() {
            this.$confirm({
                title: this.$t('meeting.warning'),
                content: this.$t('meeting.confirmDeleteConference'),
                zIndex: 1200,
                cancelText: this.$t('meeting.close'),
                okText: this.$t('meeting.delete'),
                okType: 'danger',
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.post('/table_actions/update_is_active/', [{ id: this.meeting.id, is_active: false }])
                            .then(() => {
                                this.$message.success(this.$t('meeting.conferenceDeleted'))
                                eventBus.$emit(`reload_meetings_list`, true)
                                globalEventBus.$emit(`table_row_${this.pageName}`, {
                                    action: 'delete',
                                    row: { id: this.meeting.id}
                                })
                                this.visible = false
                                resolve()
                            })
                            .catch((e) => {
                                console.log(e)
                                reject()
                            })
                    })
                },
                onCancel() {}
            })
        },
        closeConference() {
            this.$confirm({
                title: this.$t('meeting.warning'),
                content: this.$t('meeting.confirmEndConference'),
                zIndex: 1200,
                cancelText: this.$t('meeting.close'),
                okText: this.$t('meeting.end'),
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.post('/meetings/end_meeting/', { id: this.meeting.id }).
                            then(() => {
                                this.$message.success(this.$t('meeting.conferenceEnded'))
                                this.meeting.status = 'ended'
                                eventBus.$emit(`END_CONFERENCE`, this.meeting.id)
                                globalEventBus.$emit(`table_row_${this.pageName}`, {
                                    action: 'update',
                                    row: { 
                                        ...this.item,
                                        status: 'ended'
                                    }
                                })

                                resolve()
                            })
                            .catch((e) => {
                                console.log(e)
                                reject()
                            })
                    })
                },
                onCancel() {}
            })
        },
        inviteLink() {
            try {
                navigator.clipboard.writeText(this.meeting.invite_link)
                this.$message.success(this.$t('meeting.linkCopied'))
            } catch(e) {
                console.log(e)
            }
        },
        updateMeeting({meeting}) {
            this.meeting = meeting
            this.userList = {
                results: [],
                next: true,
                count: 0
            }
            this.page = 0
        },
        closeEditDrawer() {
            this.editDrawer = false
        },
        close() {
            this.meeting = null
            let query = Object.assign({}, this.$route.query)
            this.recordList = []
            this.userList = {
                results: [],
                next: true,
                count: 0
            }
            this.page = 0
            if(query.meeting) {
                delete query.meeting
                this.$router.push({query})
            }
        },
        async getMeeting() {
            try {
                this.loading = true
                const { data } = await this.$http.get(`/meetings/${this.$route.query.meeting}/detail/`)
                this.meeting = data

                if(this.meeting?.description?.length > 175)
                    this.showDescBtn = true
            } catch(error) {
                if(error && error.detail) {
                    if(error.detail === 'Не найдено.' || error.detail === 'Страница не найдена.' || error.detail === 'У вас недостаточно прав для выполнения данного действия.') {
                        this.$message.warning(this.$t('meeting.not_fount'))
                    } else {
                        this.$message.error(this.$t('meeting.error'))
                    }
                } else {
                    this.$message.error(this.$t('meeting.error'))
                }
                console.log(error)
                this.visible = false
            } finally {
                this.loading = false
            }
        },
        async getMeetingUser($state) {
            if(!this.userLoading && this.userList.next) {
                try {
                    this.userLoading = true
                    this.page += 1
                    const { data } = await this.$http.get('/meetings/members/', {
                        params: {
                            meeting: this.meeting.id,
                            page_size: 20,
                            page: this.page
                        }
                    })

                    this.userList.results = this.userList.results.concat(data.results)
                    this.userList.next = data.next
                    this.userList.count = data.count

                    if(this.userList.next)
                        $state.loaded()
                    else
                        $state.complete()
                } catch(e) {
                    console.log(e)
                } finally {
                    this.userLoading = false
                }
            }

        },
        openDrawer() {
            this.visible = true
        },
        openEditDrawer() {
            this.editDrawer = true
        }

    },

}
</script>

<style lang="scss" scoped>
.record_list{
    .item{
        cursor: pointer;
    }
}
.meeting_show{
    .user_list{
        .user_card{
            &:not(:last-child){
                border-bottom: 1px solid var(--border2);
                margin-bottom: 10px;
                padding-bottom: 10px;
            }
        }
    }
    &::v-deep{
        .ant-drawer-header-no-title{
            display: none;
        }
        .ant-drawer-content,
        .ant-drawer-wrapper-body{
            overflow: hidden;
        }
        .ant-drawer-body{
            padding: 0px;
            height: 100%;
        }
    }
    .meeting_tag{
        &::v-deep{
            .ant-tag{
                display: flex;
                align-items: center;
                &:not(:last-child){
                    margin-right: 4px;
                }
            }
        }
    }
    .drawer_header{
        height: 40px;
        border-bottom: 1px solid var(--border2);
        padding: 0 15px;
        &::v-deep{
            .ant-skeleton-title{
                margin: 0px;
            }
            .ant-skeleton-paragraph{
                display: none;
            }
        }
        .title{
            margin: 0;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 500;
            font-size: 16px;
            line-height: 22px;
        }
    }
    .drawer_body{
        padding: 15px;
        height: calc(100% - 80px);
        overflow-y: auto;
    }
    .drawer_footer{
        height: 40px;
        border-top: 1px solid var(--border2);
        padding: 0 15px;
        &::v-deep{
            .ant-skeleton-title{
                margin: 0px;
            }
            .ant-skeleton-paragraph{
                display: none;
            }
        }
    }
}
.meeting_show_mobile {
    .meeting_tag {
        flex-wrap: wrap;
        margin-bottom: -5px;
        &::v-deep{
            .ant-tag {
                margin-bottom: 5px;
            }
        }
    }
    .drawer_body{
        height: calc(100% - 88px);
    }
    .drawer_footer{
        height: 48px;
    }
}
</style>